#header {
  padding: 10px !important;
  width: 352px;
}
#header .pro-sidebar {
  transition: width, left, right, 0s;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background: transparent linear-gradient(192deg, #4295dc 0%, #3579c0 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 12px;
  opacity: 1;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: #2188cc;
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext {
  text-align: center;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: white;
  margin: 10px 0px;
  font-family: 'SF Pro Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.0075em;
  text-align: left;
  height: 100%;
  box-sizing: border-box;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: white;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: white;
  margin: 10px 0px;
  font-family: San Fransisco;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.0075em;
  text-align: left;
}

.title {
  color: white;
  margin: 10px 0px;
  text-align: left;
  font: normal normal 600 14px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active .title {
  text-align: left;
  font: normal normal 600 14px Montserrat;
  letter-spacing: 0px;
  color: #007BC6;
  opacity: 1;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active .pro-icon {
  text-align: left;
  font: normal normal 600 14px Montserrat;
  letter-spacing: 0px;
  color: #007BC6;
  opacity: 1;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  height: 74px;
}
#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

#menuItem,
#submenuItem {
  font-family: SF Pro Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.0075em;
  text-align: left;
  color: white;
}
